* {
    box-sizing: border-box;
  }
  @font-face {
    font-family: 'adineue PRO';
    src: local('adineue PRO'), url('./fonts/adineue\ PRO\ Bold\ Web\ Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'adineue PRO';
    font-weight: bold;
    src: local('adineue PRO'), url('./fonts/adineue\ PRO\ Bold\ Web\ Regular.woff') format('woff');
  }
  h1 { 
    font-family: 'adineue PRO';
    font-size: 6vw;
    /* background-image: linear-gradient( #c1ad48, #dbaead, #a1d1e6);  */
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #034b61;
  }
  html,
  body,
  #root {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    background-image:linear-gradient(to bottom left, #dbaead, #c1ad48, #a1d1e6);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: auto !important;
    /* overflow-y: visible; */
    /* overscroll-behavior: none; */
  }
  /* #root {
    overflow: scroll;
  } */
  /* Style the video: 100% width and height to cover the entire window */
  #video {
    order: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    /* left:100%; */
    height: 100%;
    /* width: 100%; */
  }
  .video-horizontal {
    position:absolute;
    display:inline-block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    min-width: 10vw;
    min-height: 10vh;
  }
  .video-vertical {
    position:absolute;
    transform-origin: top left;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    width: 100%;
    min-width: 10vw;
    min-height: 10vh;  }
  #header-logo {
    position: absolute;
    left: 50%;
    padding-top: 20px;
    transform: translateX(-50%);
    top: 0;
  }
  #header-logo-left {
    position: relative;
    padding: 10px;
    max-width: 500px;
    /* transform: translateX(-50%); */
    top: 10px;
  }
  #about-header {
    position: absolute;
    top: 0;
    /* width: 100%; */
    /* height: 10%; */
    max-height: 10%;
    width: 100vw;
  }
  #about-content {
    position: absolute;
    top: 20%;
    /* max-height: 90%; */
    padding: 20px;
  }
  .about-blurb {
    position: absolute;
    /* padding-top: 10px; */
    top:10px;
    font-style: italic;
    font-size: 4vh;
    left: 30%;
    padding-right: 10px;
  }
  #about-text {
    font-size: 20px;
  }
  #founders-statement {
    font-style: italic;
  }
  #disclaimer {
    position: absolute;
    bottom: 0;
    font-size: 2px;

  }
  #B4KLogo {
    /* position: absolute; */
    max-width: 150px;
  }
  #B4KLogoPage {
    /* position: absolute; */
    max-width: 20%;
  }
  #center-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    /* bottom: 50%; */
    /* right:50%; */
    transform: translate(-50%, -50%);
  }
  #B4KCircle {
    position: relative;
    width: 30vw;
    min-width: 5vw;
    max-width: 30vw;
    /* max-height: 50vh; */
    /* top: 0;
    left: 50%;
    transform: translate(-50%, -120%); */
  }
  #give {
    position:absolute;
    left: 0;
    top: 50%;
    height: 50vh;
    width: 50%;
    transform-origin: center;
    /* transform: rotate(-90deg); */
  }
  #get {
    position:absolute;
    left: 50%;
    top: 50%;
    height: 50vh;
    width: 50%;
    /* transform: rotate(90deg); */
  }
  .giveget {
    position:absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    /* text-orientation:sideways-right; */
    justify-content: center;
    background-color: transparent;
    border:none;
    padding: 0px;
  }
  #about {
    position:absolute;
    left: 0;
    top: 0;
    height: 50vh;
    width: 50%;
    transform-origin: center;
    /* transform: rotate(-90deg); */
  }
  #contact {
    position:absolute;
    left: 50%;
    top: 0;
    height: 50vh;
    width: 50%;
    /* transform: rotate(90deg); */
  }
  .center-form {
    position: absolute;
    align-items: center;
    width: 100vw;
  }
  .center-form form {
    position: relative;
    width: 90%;
  }
  .aboutcontact {
    position:absolute;
    left: 0;
    top: 0%;
    height: 100%;
    width: 100%;
    /* text-orientation:sideways-right; */
    justify-content: center;
    background-color: transparent;
    border:none;
    padding: 0px;
  }
  button:hover {
    background-color: rgba(255,255,255,0.05);
  }
  button:hover {
    background-color: rgba(255,255,255,0.05);
  }
  button:focus {
    background-color: rgba(125, 208, 247, 0.1);
  }
  .top-left {
    font-size: 3em;
    padding-bottom: 5vh;
    color:  #c1ad48;
  }
  .top-right {
    padding-bottom: 5vh;
    /* padding-bottom: 0vh; */
    color: #a1d1e6;
    font-size: 3em;
  }
  .bottom-left {
    padding: 2vw;
    padding-top: 15vh;
    font-size: 2.5em;
    color:  #c1ad48;
  }
  .bottom-right {
    padding: 1vw;
    padding-top: 15vh;
    font-size: 2.5em;
    color: #a1d1e6;
  }
  .rotate-left {
    transform: rotate(90deg);
  }
  .rotate-right {
    transform: rotate(-90deg);
  }
  body {
    position: fixed;
    /* overflow: scroll; */
    /* overscroll-behavior-y: none; */
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
      sans-serif;
    color: black;
  }
    
  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    /* overflow: hidden; */
  }

  input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }

  .paypal-component {
    width: 90vw;
  }
  
  .paypal-component b {
    width: 90vw;
    font-size: 12px;
    text-align:center;
  }

  #kid-with-ball {
    max-width: 200px;
  }
  
  .about-image {
    padding: 10px;
    max-width: 300px;
  }

  .about-section-1 {
    position: relative;
    font-size: 1em;
    /* top: 20% */
  }
  .about-section-2 {
    position: relative;
    /* top: 50% */
  }

  .about-section-1 p {
    /* display: block; */
    /* position: absolute; */
    /* float: left; */
    vertical-align: baseline;
    background-color: #a1d1e6;
    border-style: solid;
    border-color: black;
    border-width: 6px;
    padding: 10px;
    margin: 10px;
    max-width: 70%;
    font-size: 1em;
    /* max-width: 50% */
  }

  .about-section-1 img {
    /* display: block; */
    /* position: absolute;
    top: 0;
    right: 0; */
    vertical-align: baseline;
    float: right;
    padding: 10px;
    max-width: 300px;
  }

  .about-section-2 p {
    /* position: absolute; */
    display: block;
    top: 0;
    right: 0;
    float:right;
    background-color: #dbaead;
    border-style: solid;
    border-color: black;
    border-width: 6px;
    padding: 10px;
    margin: 10px;
    max-width: 70%;
    /* max-width: 50%; */
  }

  .about-section-2 img {
    /* position: absolute; */
    display: block;
    float: left; 
    padding: 10px;
    max-width: 300px;
  }